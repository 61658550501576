import { store } from '@features/store'
import { apiPost } from '@utils/api'

const monduPayment = async (payment_method = 'mondu') => {
  const cart = store.getState().cart
  const user = store.getState().user.current
  const currentStore = store.getState().store
  const returnURL = `${process.env.NEXT_PUBLIC_DOMAIN}/${currentStore.language.code}-${currentStore.country.code}-${currentStore.currency.code}/checkout/order-review`
  const res = await apiPost(
    `ext/ggm-mondu/orders?storeCode=${currentStore.language.code}`,
    {
      cartId: parseInt(cart.id),
      customer_email: user.email,
      payment_method,
      success_url: `${returnURL}?success=${payment_method}`,
      cancel_url: returnURL,
      error_url: `${returnURL}?error=${payment_method}`,
    },
  )
  return res
}

export default monduPayment
