export const appConfig = {
  queryString: ['page', 'price', 'average_review', 'sort', 'q'],
  staticPages: [
    'checkout',
    'landing',
    'my-account',
    'oauth_callback',
    'shared-cart',
    'cart',
    'categorylist',
    'storyblok',
    'appcheckout',
    'appregister',
    'appforwarder',
    'leasing',
  ],
  defaultSortParams: {
    label: 'Recommended by GGM',
    value: 'second_choice:asc,classification:asc,price:asc',
  },
  cartConfig: {
    leasingLimit: 500,
    leasingCountries: ['de'],
  },
  sortParams: [
    {
      label: 'Recommended by GGM',
      value: 'second_choice:asc,classification:asc,price:asc',
    },
    {
      label: 'Price: Low to high',
      value: 'price:asc',
    },
    {
      label: 'Price: High to low',
      value: 'price:desc',
    },
    {
      label: 'Instant delivery',
      value: 'deliverytime.normalized:asc',
    },
  ],
  googlePlaces: {
    baseUrl: 'https://maps.googleapis.com/maps/api/js?libraries=places&key=',
    apiKey: 'AIzaSyD6_M-Y5fEGu2iQSXl_8W5yMQ5ZRpDLih0',
  },
  adyen: {
    excludePaymentMethods: ['bankTransfer', 'cash', 'debit', 'offer'],
    paymentMethodSort: {
      de: {
        ggmPayPal: 0,
        scheme: 1,
        directEbanking: 2,
        bankTransfer: 3,
      },
      fr: {
        cartebancaire: 0,
        scheme: 1,
        ggmPayPal: 2,
        bankTransfer: 3,
      },
      pl: {
        dotpay: 0,
        scheme: 1,
        ggmPayPal: 2,
        bankTransfer: 3,
      },
      be: {
        bcmc: 0,
        scheme: 1,
        ggmPayPal: 2,
        directEbanking: 3,
        bankTransfer: 4,
      },
      it: {
        scheme: 0,
        ggmPayPal: 1,
        bankTransfer: 2,
      },
      nl: {
        ideal: 0,
        scheme: 1,
        sepadirectdebit: 2,
        directEbanking: 3,
        ggmPayPal: 4,
        bankTransfer: 5,
      },
      pt: {
        multibanco: 0,
        scheme: 1,
        ggmPayPal: 2,
        bankTransfer: 3,
      },
      es: {
        scheme: 0,
        ggmPayPal: 1,
        bankTransfer: 2,
      },
    },
  },
  shareLinks: [
    {
      name: 'facebook',
      title: 'Facebook',
      link: 'https://www.facebook.com/sharer/sharer.php?u=@url&title=@title&description=@description',
    },
    {
      name: 'x',
      title: 'X',
      link: 'https://x.com/intent/tweet?text=@title&url=@url',
    },
    {
      name: 'whatsapp',
      title: 'Whatsapp',
      link: 'https://api.whatsapp.com/send?text=@title%0D%0A@url%0D%0A@description',
    },
    {
      name: 'linkedin',
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/sharing/share-offsite/?url=@url',
    },
    {
      name: 'xing',
      title: 'XING',
      link: 'https://www.xing.com/app/user?op=share&url=@url&title=@title',
    },
    {
      name: 'mail',
      title: 'E-Mail',
      link: 'mailto:?subject=@title&body=@url%0D%0A@description',
    },
  ],
  algoliasearch: {
    default: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_de_de_`,
    de: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_de_de_`,
    ar: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_ar_ae_`,
    bg: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_bg_bg_`,
    cs: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_cs_cz_`,
    da: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_da_dk_`,
    nl: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_nl_nl_`,
    en: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_en_gb_`,
    fi: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_fi_fi_`,
    fr: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_fr_fr_`,
    el: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_el_gr_`,
    hu: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_hu_hu_`,
    it: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_it_it_`,
    nb: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_nb_no_`,
    fa: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_fa_ir_`,
    pl: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_pl_pl_`,
    ro: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_ro_ro_`,
    ru: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_ru_ru_`,
    es: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_es_es_`,
    sv: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_sv_se_`,
    tr: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_tr_tr_`,
    uk: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_uk_ua_`,
    hr: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_hr_hr_`,
    pt: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_KEY}_pt_pt_`,
  },
  elasticsearch: {
    default: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}de_de`,
    de: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}de_de`,
    ar: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}ar_ae`,
    bg: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}bg_bg`,
    cs: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}cs_cz`,
    da: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}da_dk`,
    nl: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}nl_nl`,
    en: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}en_gb`,
    fi: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}fi_fi`,
    fr: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}fr_fr`,
    el: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}el_gr`,
    hu: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}hu_hu`,
    it: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}it_it`,
    nb: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}nb_no`,
    fa: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}fa_ir`,
    pl: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}pl_pl`,
    ro: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}ro_ro`,
    ru: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}ru_ru`,
    es: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}es_es`,
    sv: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}sv_se`,
    tr: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}tr_tr`,
    uk: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}uk_ua`,
    hr: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}hr_hr`,
    pt: `${process.env.NEXT_PUBLIC_ELASTICSEARCH_INDEX_NAME}pt_pt`,
  },
  custom_attributes: [
    'action',
    'activity',
    'average_review_count',
    'average_review',
    'capacity',
    'category',
    'cms_content_page',
    'connection',
    'cooking_series',
    'cooling_range',
    'datasheet',
    'deliverytime',
    'display_mode_akeneo',
    'errors',
    'energy_label',
    'energy_label_export',
    'energy_efficiency_class',
    'exploded_view',
    'fat_drawer',
    'final_price',
    'fully_chamotte_lined',
    'ggm_business_type',
    'ggm_how_you_heard',
    'glass_door_type',
    'grill_plate_surface',
    'gross_depth',
    'gross_width',
    'id',
    'image',
    'info',
    'leasable',
    'manual',
    'name',
    'net_depth',
    'net_dimensions',
    'net_height',
    'net_width',
    'new_article',
    'new',
    'no_leasing',
    'number_of_baking_chambers',
    'number_of_burners',
    'number_of_doors',
    'number_of_drawers',
    'number_of_pizzas_per_chamber',
    'number_of_pizzas_per_hour_per_baking_chamber',
    'orbitvu_id',
    'orbitvu_script',
    'orbitvu_thumbnail',
    'order_in_shop',
    'original_price_incl_tax',
    'original_price',
    'oven_type',
    'power_connection',
    'power',
    'price_incl_tax',
    'price',
    'product_label',
    'product_links',
    'quality_level',
    'recommended_filling_quantity',
    'request_item',
    'sale',
    'sales_blacklist',
    'sales_price_*',
    'second_choice',
    'shipping_method',
    'short_description',
    'show_on_storefront_customers_recommend',
    'show_on_storefront',
    'sku',
    'spareparts_list',
    'spare_list_products',
    'special_features',
    'special_from_date',
    'special_price_incl_tax',
    'special_price',
    'special_to_date',
    'status',
    'stock',
    'tax_class_id',
    'temperature_range_to',
    'tier_prices',
    'type_id',
    'upstand',
    'url_key',
    'url_path',
    'videos_data',
  ],
  userMenu: [
    { title: 'profile', link: 'my-account', salesAgency: false },
    {
      title: 'addressbook',
      link: 'my-account/addressbook',
      salesAgency: false,
    },
    { title: 'orders', link: 'my-account/orders', salesAgency: false },
    {
      title: 'recently viewed products',
      link: 'my-account/recently-viewed',
      salesAgency: false,
    },
    { title: 'my wishlist', link: 'my-account/wishlist', salesAgency: false },
    {
      title: 'Reclamation',
      link: 'https://helpcenter.ggmgastro.com/',
      salesAgency: false,
    },
    {
      title: 'Contact',
      link: 'https://helpcenter.ggmgastro.com/',
      salesAgency: false,
    },
    {
      title: 'Logout',
      link: '',
      salesAgency: false,
    },
    { title: 'profile', link: 'my-account', salesAgency: true },
    {
      title: 'addressbook',
      link: 'my-account/addressbook',
      salesAgency: true,
    },
    {
      title: 'recently viewed products',
      link: 'my-account/recently-viewed',
      salesAgency: true,
    },
    { title: 'my wishlist', link: 'my-account/wishlist', salesAgency: true },
    {
      title: 'Products',
      link: 'my-account/sales-agency/products',
      salesAgency: true,
    },
    {
      title: 'Assigned customers',
      link: 'my-account/sales-agency/customers',
      salesAgency: true,
    },
    {
      title: 'All orders',
      link: 'my-account/sales-agency/all-orders',
      salesAgency: true,
    },

    {
      title: 'Get PayByLink from Magento Order',
      link: 'my-account/sales-agency/get-pay-by-link',
      salesAgency: true,
    },
    {
      title: 'Create PayByLink',
      link: 'my-account/sales-agency/create-pay-by-link',
      salesAgency: true,
    },
    {
      title: 'Logout',
      link: '',
      salesAgency: true,
    },
  ],
  storeViews: {
    default: {
      additionalMainNavItems: [
        {
          title: 'Stühle & Tische',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      storeId: 2,
    },
    de: {
      additionalMainNavItems: [
        {
          title: 'Stühle & Tische',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['de'],
          currency: ['eur'],
        },
        index: {
          country: ['de'],
          currency: ['eur'],
        },
      },
      storeId: 2,
    },
    ar: {
      additionalMainNavItems: [
        {
          title: 'الكراسي والطاولات',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['de'],
          currency: ['eur'],
        },
        index: {
          country: ['de'],
          currency: ['eur'],
        },
      },
      storeId: 3,
    },
    bg: {
      additionalMainNavItems: [
        {
          title: 'Столове и маси',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['bg'],
          currency: ['bgn'],
        },
        index: {
          country: ['bg'],
          currency: ['bgn'],
        },
      },
      storeId: 4,
    },
    da: {
      additionalMainNavItems: [
        {
          title: 'Stole & Borde',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['dk'],
          currency: ['dkk'],
        },
        index: {
          country: ['dk'],
          currency: ['dkk'],
        },
      },
      storeId: 6,
    },
    nl: {
      additionalMainNavItems: [
        {
          title: 'Stoelen & Tafels',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['nl'],
          currency: ['eur'],
        },
        index: {
          country: ['nl'],
          currency: ['eur'],
        },
      },
      storeId: 7,
    },
    en: {
      additionalMainNavItems: [
        {
          title: 'Chairs & Tables',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['gb'],
          currency: ['gbp'],
        },
        index: {
          country: ['gb'],
          currency: ['gbp'],
        },
      },
      storeId: 8,
    },
    fi: {
      additionalMainNavItems: [
        {
          title: 'Tuolit & Pöydät',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['fi'],
          currency: ['eur'],
        },
        index: {
          country: ['fi'],
          currency: ['eur'],
        },
      },
      storeId: 9,
    },
    fr: {
      additionalMainNavItems: [
        {
          title: 'Chaises & Tables',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['fr'],
          currency: ['eur'],
        },
        index: {
          country: ['fr'],
          currency: ['eur'],
        },
      },
      storeId: 10,
    },
    el: {
      additionalMainNavItems: [
        {
          title: 'Καρέκλες & Τραπέζια',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['gr'],
          currency: ['eur'],
        },
        index: {
          country: ['gr'],
          currency: ['eur'],
        },
      },
      storeId: 11,
    },
    hu: {
      additionalMainNavItems: [
        {
          title: 'Székek és asztalok',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['hu'],
          currency: ['huf'],
        },
        index: {
          country: ['hu'],
          currency: ['huf'],
        },
      },
      storeId: 12,
    },
    it: {
      additionalMainNavItems: [
        {
          title: 'Sedie & Tavoli',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['it'],
          currency: ['eur'],
        },
        index: {
          country: ['it'],
          currency: ['eur'],
        },
      },
      storeId: 13,
    },
    nb: {
      additionalMainNavItems: [
        {
          title: 'Stoler & Bord',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['no'],
          currency: ['nok'],
        },
        index: {
          country: ['no'],
          currency: ['nok'],
        },
      },
      storeId: 14,
    },
    fa: {
      additionalMainNavItems: [
        {
          title: 'صندلی و میز',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['de'],
          currency: ['eur'],
        },
        index: {
          country: ['de'],
          currency: ['eur'],
        },
      },
      storeId: 15,
    },
    pl: {
      additionalMainNavItems: [
        {
          title: 'Krzesła & Stoły',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['pl'],
          currency: ['pln'],
        },
        index: {
          country: ['pl'],
          currency: ['pln'],
        },
      },
      storeId: 16,
    },
    ro: {
      additionalMainNavItems: [
        {
          title: 'Scaune și mese',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['ro'],
          currency: ['ron'],
        },
        index: {
          country: ['ro'],
          currency: ['ron'],
        },
      },
      storeId: 17,
    },
    ru: {
      additionalMainNavItems: [
        {
          title: 'Стулья и столы',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['de'],
          currency: ['eur'],
        },
        index: {
          country: ['de'],
          currency: ['eur'],
        },
      },
      storeId: 18,
    },
    es: {
      additionalMainNavItems: [
        {
          title: 'Artículos con envío express gratis',
          url: 'articulos-destacados-con-envio-express-gratis',
          icon: '/ggmmoebel-icon.png?v=1',
          external: false,
          locale: true,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['es'],
          currency: ['eur'],
        },
        index: {
          country: ['es'],
          currency: ['eur'],
        },
      },
      storeId: 19,
    },
    sv: {
      additionalMainNavItems: [
        {
          title: 'Stolar & Bord',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['se'],
          currency: ['sek'],
        },
        index: {
          country: ['se'],
          currency: ['sek'],
        },
      },
      storeId: 20,
    },
    tr: {
      additionalMainNavItems: [
        {
          title: 'Sandalyeler & Masalar',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['tr'],
          currency: ['eur'],
        },
        index: {
          country: ['tr'],
          currency: ['eur'],
        },
      },
      storeId: 21,
    },
    uk: {
      additionalMainNavItems: [
        {
          title: 'Стільці та столи',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['ua'],
          currency: ['eur'],
        },
        index: {
          country: ['ua'],
          currency: ['eur'],
        },
      },
      storeId: 22,
    },
    hr: {
      additionalMainNavItems: [
        {
          title: 'Stolice i stolovi',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['de'],
          currency: ['eur'],
        },
        index: {
          country: ['de'],
          currency: ['eur'],
        },
      },
      storeId: 5,
    },
    pt: {
      additionalMainNavItems: [
        {
          title: 'Móveis de bistrô',
          url: 'b-ware-e-liquidacao/liquidacao',
          icon: '/ggmmoebel-icon.png?v=1',
          external: false,
          locale: true,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['pt'],
          currency: ['eur'],
        },
        index: {
          country: ['pt'],
          currency: ['eur'],
        },
      },
      storeId: 23,
    },
    cs: {
      additionalMainNavItems: [
        {
          title: 'Židle a stoly',
          url: 'https://www.ggmmoebel.com',
          icon: '/ggmmoebel-icon.png?v=1',
          external: true,
          locale: false,
        },
      ],
      seo: {
        defaultTitle: 'GGM Gastro',
        follow: {
          country: ['cz'],
          currency: ['czk'],
        },
        index: {
          country: ['cz'],
          currency: ['czk'],
        },
      },
      storeId: 24,
    },
  },
  i18n: {
    default: {
      language: 'de',
      country: 'de',
      currency: 'eur',
    },
    de: {
      language: 'de',
      country: 'de',
      currency: 'eur',
    },
    ar: {
      language: 'ar',
      country: 'de',
      currency: 'eur',
    },
    bg: {
      language: 'bg',
      country: 'bg',
      currency: 'bgn',
    },
    da: {
      language: 'da',
      country: 'dk',
      currency: 'dkk',
    },
    nl: {
      language: 'nl',
      country: 'nl',
      currency: 'eur',
    },
    en: {
      language: 'en',
      country: 'gb',
      currency: 'gbp',
    },
    fi: {
      language: 'fi',
      country: 'fi',
      currency: 'eur',
    },
    fr: {
      language: 'fr',
      country: 'fr',
      currency: 'eur',
    },
    el: {
      language: 'el',
      country: 'gr',
      currency: 'eur',
    },
    hu: {
      language: 'hu',
      country: 'hu',
      currency: 'huf',
    },
    it: {
      language: 'it',
      country: 'it',
      currency: 'eur',
    },
    nb: {
      language: 'nb',
      country: 'no',
      currency: 'nok',
    },
    fa: {
      language: 'fa',
      country: 'de',
      currency: 'eur',
    },
    pl: {
      language: 'pl',
      country: 'pl',
      currency: 'pln',
    },
    ro: {
      language: 'ro',
      country: 'ro',
      currency: 'ron',
    },
    ru: {
      language: 'ru',
      country: 'de',
      currency: 'eur',
    },
    es: {
      language: 'es',
      country: 'es',
      currency: 'eur',
    },
    sv: {
      language: 'sv',
      country: 'se',
      currency: 'sek',
    },
    tr: {
      language: 'tr',
      country: 'de',
      currency: 'eur',
    },
    uk: {
      language: 'uk',
      country: 'ua',
      currency: 'eur',
    },
    hr: {
      language: 'hr',
      country: 'hr',
      currency: 'eur',
    },
    pt: {
      language: 'pt',
      country: 'pt',
      currency: 'eur',
    },
    cs: {
      language: 'cs',
      country: 'cz',
      currency: 'czk',
    },
  },
  deliveryCountry: [
    {
      code: 'de',
      name: 'Germany',
      defaultCurrency: 'eur',
      defaultLanguage: 'de',
    },
    {
      code: 'ch',
      name: 'Switzerland',
      defaultCurrency: 'chf',
      defaultLanguage: 'de',
      salesPriceAttributeSuffix: 'ch',
    },
    {
      code: 'be',
      name: 'Belgium',
      defaultCurrency: 'eur',
      defaultLanguage: 'fr',
      salesPriceAttributeSuffix: 'be',
    },
    {
      code: 'at',
      name: 'Austria',
      defaultCurrency: 'eur',
      defaultLanguage: 'de',
    },
    {
      code: 'cz',
      name: 'Czech Republic',
      defaultCurrency: 'czk',
      defaultLanguage: 'cs',
    },
    {
      code: 'fr',
      name: 'France',
      defaultCurrency: 'eur',
      defaultLanguage: 'fr',
    },
    {
      code: 'gb',
      name: 'Great Britain',
      defaultCurrency: 'gbp',
      defaultLanguage: 'en',
      salesPriceAttributeSuffix: 'uk',
    },
    {
      code: 'ie',
      name: 'Ireland',
      defaultCurrency: 'eur',
      defaultLanguage: 'en',
    },
    {
      code: 'it',
      name: 'Italy',
      defaultCurrency: 'eur',
      defaultLanguage: 'it',
    },
    {
      code: 'lu',
      name: 'Luxembourg',
      defaultCurrency: 'eur',
      defaultLanguage: 'fr',
      salesPriceAttributeSuffix: 'lu',
    },
    {
      code: 'lv',
      name: 'Latvia',
      defaultCurrency: 'eur',
      defaultLanguage: 'en',
    },
    {
      code: 'tr',
      name: 'Turkey',
      defaultCurrency: 'eur',
      defaultLanguage: 'en',
      'external-link': 'https://www.ggmgastro.tr',
    },
    {
      code: 'nl',
      name: 'Netherlands',
      defaultCurrency: 'eur',
      defaultLanguage: 'nl',
      salesPriceAttributeSuffix: 'nl',
    },
    {
      code: 'pl',
      name: 'Poland',
      defaultCurrency: 'pln',
      defaultLanguage: 'pl',
    },
    {
      code: 'ua',
      name: 'Ukraine',
      defaultCurrency: 'eur',
      defaultLanguage: 'uk',
    },
    {
      code: 'dk',
      name: 'Denmark',
      defaultCurrency: 'dkk',
      defaultLanguage: 'dk',
      'external-link': 'https://www.ggmgastro.dk/dk',
      salesPriceAttributeSuffix: 'de',
    },
    {
      code: 'no',
      name: 'Norway',
      defaultCurrency: 'nok',
      defaultLanguage: 'nb',
      'external-link': 'https://www.ggmgastro.no/no',
      salesPriceAttributeSuffix: 'de',
    },
    {
      code: 'se',
      name: 'Sweden',
      defaultCurrency: 'sek',
      defaultLanguage: 'sv',
    },
    {
      code: 'fi',
      name: 'Finland',
      defaultCurrency: 'eur',
      defaultLanguage: 'fi',
    },
    {
      code: 'es',
      name: 'Spain',
      defaultCurrency: 'eur',
      defaultLanguage: 'es',
    },
    {
      code: 'pt',
      name: 'Portugal',
      defaultCurrency: 'eur',
      defaultLanguage: 'pt',
    },
    {
      code: 'bg',
      name: 'Bulgaria',
      'external-link': 'https://www.ggmgastro.bg/bg',
      salesPriceAttributeSuffix: 'de',
    },
    {
      code: 'gr',
      name: 'Greece',
      salesPriceAttributeSuffix: 'de',
    },
    {
      code: 'hu',
      name: 'Hungary',
      salesPriceAttributeSuffix: 'de',
    },
    {
      code: 'ro',
      name: 'Romania',
      salesPriceAttributeSuffix: 'de',
    },
    {
      code: 'hr',
      name: 'Croatia',
      defaultCurrency: 'hrk',
      defaultLanguage: 'hr',
    },
    {
      code: 'ee',
      name: 'Estonia',
      defaultCurrency: 'eur',
      defaultLanguage: 'en',
    },
    {
      code: 'lt',
      name: 'Lithuania',
      defaultCurrency: 'eur',
      defaultLanguage: 'lt',
    },
    {
      code: 'cy',
      name: 'Cyprus',
      defaultCurrency: 'eur',
      defaultLanguage: 'el',
    },
    {
      code: 'mt',
      name: 'Malta',
      defaultCurrency: 'eur',
      defaultLanguage: 'en',
    },
    {
      code: 'sk',
      name: 'Slovakia',
      defaultCurrency: 'eur',
      defaultLanguage: 'en',
    },
    {
      code: 'si',
      name: 'Slovenia',
      defaultCurrency: 'eur',
      defaultLanguage: 'en',
    },
  ],
  currency: [
    {
      code: 'eur',
      sign: 'EUR',
      name: 'EUR',
      symbol: '€',
    },
    {
      code: 'chf',
      sign: 'CHF',
      name: 'CHF',
      symbol: 'Fr',
    },
    {
      code: 'usd',
      sign: 'USD',
      name: 'USD',
      symbol: '$',
    },
    {
      code: 'gbp',
      sign: 'GBP',
      name: 'GBP',
      symbol: '£',
    },
    {
      code: 'bgn',
      sign: 'BGN',
      name: 'BGN',
      symbol: 'лв',
    },
    {
      code: 'dkk',
      sign: 'DKK',
      name: 'DKK',
      symbol: 'kr',
    },
    {
      code: 'nok',
      sign: 'NOK',
      name: 'NOK',
      symbol: 'kr',
    },
    {
      code: 'pln',
      sign: 'PLN',
      name: 'PLN',
      symbol: 'zł',
    },
    {
      code: 'ron',
      sign: 'RON',
      name: 'RON',
      symbol: 'lei',
    },
    {
      code: 'sek',
      sign: 'SEK',
      name: 'SEK',
      symbol: 'kr',
    },
    {
      code: 'huf',
      sign: 'HUF',
      name: 'HUF',
      symbol: 'Ft',
    },
    {
      code: 'czk',
      sign: 'CZK',
      name: 'CZK',
      symbol: 'Kč',
    },
    {
      code: 'hrk',
      sign: 'HRK',
      name: 'HRK',
      symbol: 'kn',
    },
  ],
  languages: [
    {
      code: 'de',
      name: 'Deutsch',
      flagCode: 'de',
      locale: 'de-DE',
    },
    {
      code: 'ar',
      name: 'العربية',
      flagCode: 'ar',
      locale: 'ar-SY',
    },
    {
      code: 'bg',
      name: 'Български',
      flagCode: 'bg',
      locale: 'bg-BG',
    },
    {
      code: 'da',
      name: 'Dansk',
      flagCode: 'da',
      locale: 'da-DK',
    },
    {
      code: 'nl',
      name: 'Nederlands',
      flagCode: 'nl',
      locale: 'nl-NL',
    },
    {
      code: 'en',
      name: 'English',
      flagCode: 'en',
      locale: 'en-US',
    },
    {
      code: 'fi',
      name: 'Suomi',
      flagCode: 'fi',
      locale: 'fi-FI',
    },
    {
      code: 'fr',
      name: 'Français',
      flagCode: 'fr',
      locale: 'fr-FR',
    },
    {
      code: 'el',
      name: 'Ελληνικά',
      flagCode: 'el',
      locale: 'el-GR',
    },
    {
      code: 'hu',
      name: 'Magyar',
      flagCode: 'hu',
      locale: 'hu-HU',
    },
    {
      code: 'it',
      name: 'Italiano',
      flagCode: 'it',
      locale: 'it-IT',
    },
    {
      code: 'nb',
      name: 'Norsk bokmål',
      flagCode: 'nb',
      locale: 'nb-NO',
    },
    {
      code: 'fa',
      name: 'فارسی',
      flagCode: 'fa',
      locale: 'fa-IR',
    },
    {
      code: 'pl',
      name: 'Polski',
      flagCode: 'pl',
      locale: 'pl-PL',
    },
    {
      code: 'pt',
      name: 'Português',
      flagCode: 'pt',
      locale: 'pt-PT',
    },
    {
      code: 'ro',
      name: 'Română',
      flagCode: 'ro',
      locale: 'ro-RO',
    },
    {
      code: 'ru',
      name: 'Русский',
      flagCode: 'ru',
      locale: 'ru-RU',
    },
    {
      code: 'es',
      name: 'Español',
      flagCode: 'es',
      locale: 'es-ES',
    },
    {
      code: 'sv',
      name: 'Svenska',
      flagCode: 'se',
      locale: 'sv-SE',
    },
    {
      code: 'tr',
      name: 'Türkçe',
      flagCode: 'tr',
      locale: 'tr-TR',
    },
    {
      code: 'uk',
      name: 'Український',
      flagCode: 'uk',
      locale: 'uk-UA',
    },
    {
      code: 'hr',
      name: 'Hrvatski',
      flagCode: 'hr',
      locale: 'hr-HR',
    },
    {
      code: 'cs',
      name: 'Čeština',
      flagCode: 'cz',
      locale: 'cs-CZ',
    },
  ],
  taxId: [
    {
      country: 'Andorra',
      code: 'ad',
      vatIdCode: 'AD',
      example: null,
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Austria',
      code: 'at',
      vatIdCode: 'ATU',
      example: 'ATU99999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Belgium',
      code: 'be',
      vatIdCode: 'BE',
      example: 'BE0999999999',
      example_2: 'BE9999999999',
      example_3: null,
      example_4: null,
    },
    {
      country: 'Brazil',
      code: 'br',
      vatIdCode: 'BR',
      example: null,
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Bulgaria',
      code: 'bg',
      vatIdCode: 'BG',
      example: 'BG999999999',
      example_2: 'BG9999999999',
      example_3: null,
      example_4: null,
    },
    {
      country: 'Croatia',
      code: 'hr',
      vatIdCode: 'HR',
      example: 'HR99999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Cyprus',
      code: 'cy',
      vatIdCode: 'CY',
      example: 'CY99999999L',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Czech Republic',
      code: 'cz',
      vatIdCode: 'CZ',
      example: 'CZ99999999',
      example_2: 'CZ999999999',
      example_3: 'CZ9999999999',
      example_4: null,
    },
    {
      country: 'Denmark',
      code: 'dk',
      vatIdCode: 'DK',
      example: 'DK99999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Estonia',
      code: 'ee',
      vatIdCode: 'EE',
      example: 'EE999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Europe',
      code: 'eu',
      vatIdCode: 'EU',
      example: null,
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Finland',
      code: 'fi',
      vatIdCode: 'FI',
      example: 'FI99999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'France',
      code: 'fr',
      vatIdCode: 'FR',
      example: 'FRXX999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Germany',
      code: 'de',
      vatIdCode: 'DE',
      example: 'DE999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Greece',
      code: 'gr',
      vatIdCode: 'EL',
      example: 'EL999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Hungary',
      code: 'hu',
      vatIdCode: 'HU',
      example: ' HU99999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Ireland',
      code: 'ie',
      vatIdCode: 'IE',
      example: 'IE9S99999L',
      example_2: 'IE9999999LI',
      example_3: null,
      example_4: null,
    },
    {
      country: 'Italy',
      code: 'it',
      vatIdCode: 'IT',
      example: 'IT99999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Latvia',
      code: 'lv',
      vatIdCode: 'LV',
      example: 'LV99999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Lithuania',
      code: 'lt',
      vatIdCode: 'LT',
      example: 'LT999999999',
      example_2: 'LT999999999999',
      example_3: null,
      example_4: null,
    },
    {
      country: 'Luxembourg',
      code: 'lu',
      vatIdCode: 'LU',
      example: 'LU99999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Malta',
      code: 'mt',
      vatIdCode: 'MT',
      example: 'MT99999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Netherlands',
      code: 'nl',
      vatIdCode: 'NL',
      example: 'NL999999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Norway',
      code: 'no',
      vatIdCode: 'NO',
      example: null,
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Poland',
      code: 'pl',
      vatIdCode: 'PL',
      example: 'PL9999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Portugal',
      code: 'pt',
      vatIdCode: 'PT',
      example: 'PT999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Romania',
      code: 'ro',
      vatIdCode: 'RO',
      example: 'RO999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Russia',
      code: 'ru',
      vatIdCode: 'RU',
      example: null,
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Serbia',
      code: 'rs',
      vatIdCode: 'RS',
      example: null,
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Slovakia',
      code: 'sk',
      vatIdCode: 'SK',
      example: 'SK9999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Slovenia',
      code: 'si',
      vatIdCode: 'SI',
      example: 'SI99999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Spain',
      code: 'es',
      vatIdCode: 'ES',
      example: 'ESX9999999X',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Sweden',
      code: 'se',
      vatIdCode: 'SE',
      example: 'SE999999999999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'Switzerland',
      code: 'ch',
      vatIdCode: 'CHE',
      example: 'CHE-999.999.999',
      example_2: null,
      example_3: null,
      example_4: null,
    },
    {
      country: 'United Kingdom',
      code: 'gb',
      vatIdCode: 'GB',
      example: 'GB999999999',
      example_2: 'GB999999999999',
      example_3: 'GBGD999',
      example_4: 'GBHA999',
    },
  ],
  nonDeliverableCountries: {
    BG: 'https://ggmgastro.bg/bg',
    DK: 'https://ggmgastro.dk/dk',
    NO: 'https://ggmgastro.no/no',
    TR: '',
  },
}
