import { store } from '@features/store'
import countriesData from '@utils/json/countries.json'

const paymentData = (order_uuid) => {
  // Get store state data
  const { checkout, cart, store: storeState, user } = store.getState()
  const { currency, language } = storeState
  const { current, salesAgent } = user

  // Check if all products are virtual
  const isVirtual = cart.products.every((item) =>
    ['downloadable', 'virtual'].includes(item.type_id),
  )

  // Get phone codes with fallback
  const getPhoneCode = (countryCode) =>
    countriesData.find(
      (c) => c.code.toLowerCase() === countryCode?.toLowerCase(),
    )?.phone || '49'

  const billingPhoneCode = getPhoneCode(
    checkout.paymentDetails.phoneNumberCountryCode,
  )
  const billingMobilePhoneCode = getPhoneCode(
    checkout.paymentDetails.mobilePhoneNumberCountryCode,
  )
  const shippingPhoneCode = getPhoneCode(
    checkout.shippingDetails.phoneNumberCountryCode,
  )
  const shippingMobilePhoneCode = getPhoneCode(
    checkout.shippingDetails.mobilePhoneNumberCountryCode,
  )

  // Construct base data object
  const data = {
    store_code: language.code,
    user_id: (salesAgent ? current.id : checkout.personalDetails.id).toString(),
    cart_id: cart.id.toString(),
    products: cart.products,
    currencySign: currency.code.toUpperCase(),
    addressInformation: {
      billingAddress: {
        region: '',
        region_id: 0,
        country_id: checkout.paymentDetails.countryId.toUpperCase(),
        street: [
          checkout.paymentDetails.street,
          checkout.paymentDetails.houseNumber,
        ],
        company: checkout.paymentDetails.company,
        telephone:
          checkout.paymentDetails.phoneNumber?.length > 0
            ? `+${billingPhoneCode}${checkout.paymentDetails.phoneNumber}`
            : '',
        postcode: checkout.paymentDetails.postcode,
        city: checkout.paymentDetails.city,
        prefix: checkout.paymentDetails.prefix || '',
        firstname: checkout.paymentDetails.firstname,
        lastname: checkout.paymentDetails.lastname,
        email: checkout.personalDetails.email,
        region_code: '',
        extension_attributes: {
          mobilephone:
            checkout.paymentDetails.mobilePhoneNumber?.length > 0
              ? `+${billingMobilePhoneCode}${checkout.paymentDetails.mobilePhoneNumber}`
              : '',
          nif: checkout.paymentDetails.nif || '',
        },
      },
      shipping_method_code: checkout.shippingMethod.code || 'uninsured',
      shipping_carrier_code: checkout.shippingMethod.carrier || 'uninsured',
      payment_method_code: checkout.paymentMethod.code,
      payment_method_additional: checkout.paymentMethod.additional,
    },
  }

  // Add VAT ID for non-German addresses
  if (checkout.paymentDetails.countryId.toUpperCase() !== 'DE') {
    data.addressInformation.billingAddress.vat_id =
      checkout.paymentDetails.taxId?.length > 3
        ? checkout.paymentDetails.taxId
        : ''
  }

  // Add shipping address for non-virtual products
  if (!isVirtual) {
    data.addressInformation.shippingAddress = {
      region: '',
      region_id: 0,
      country_id: checkout.shippingDetails.countryId.toUpperCase(),
      street: [
        checkout.shippingDetails.street,
        checkout.shippingDetails.houseNumber,
      ],
      telephone:
        checkout.shippingDetails.phoneNumber?.length > 0
          ? `+${shippingPhoneCode}${checkout.shippingDetails.phoneNumber}`
          : '',
      postcode: checkout.shippingDetails.postcode,
      city: checkout.shippingDetails.city,
      prefix: checkout.paymentDetails.prefix || '',
      firstname: checkout.shippingDetails.firstname,
      lastname: checkout.shippingDetails.lastname,
      email: checkout.personalDetails.email,
      region_code: '',
      company: checkout.shippingDetails.company,
      extension_attributes: {
        mobilephone:
          checkout.shippingDetails.mobilePhoneNumber?.length > 0
            ? `+${shippingMobilePhoneCode}${checkout.shippingDetails.mobilePhoneNumber}`
            : '',
      },
    }
  }

  // Add order UUID if provided
  if (order_uuid) {
    data.addressInformation.payment_method_additional = { uuid: order_uuid }
  }

  return data
}

export default paymentData
